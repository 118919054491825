// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-container {
  position: relative;
  display: block;
  padding-bottom: var(--padding-bottom, 0);
  overflow: hidden;
  background-color: var(--surface-ground);
  background-image: var(--image-small);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.image-container.loaded {
  background: none;
}

img {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
}

.crop-contain {
  object-fit: contain;
}

.crop-cover {
  object-fit: cover;
}`, "",{"version":3,"sources":["webpack://./src/app/core/components/image/image.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,cAAA;EACA,wCAAA;EACA,gBAAA;EACA,uCAAA;EACA,oCAAA;EACA,sBAAA;EACA,2BAAA;EACA,4BAAA;AACF;AACE;EACE,gBAAA;AACJ;;AAGA;EACE,kBAAA;EACA,QAAA;EACA,WAAA;EACA,YAAA;AAAF;;AAGA;EACE,mBAAA;AAAF;;AAGA;EACE,iBAAA;AAAF","sourcesContent":[".image-container {\r\n  position: relative;\r\n  display: block;\r\n  padding-bottom: var(--padding-bottom, 0);\r\n  overflow: hidden;\r\n  background-color: var(--surface-ground);\r\n  background-image: var(--image-small);\r\n  background-size: cover;\r\n  background-position: center;\r\n  background-repeat: no-repeat;\r\n\r\n  &.loaded {\r\n    background: none;\r\n  }\r\n}\r\n\r\nimg {\r\n  position: absolute;\r\n  inset: 0;\r\n  width: 100%;\r\n  height: 100%;\r\n}\r\n\r\n.crop-contain {\r\n  object-fit: contain;\r\n}\r\n\r\n.crop-cover {\r\n  object-fit: cover;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

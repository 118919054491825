// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `section {
  min-height: 100vh;
}
@media only screen and (min-width: 1200px) {
  section {
    min-height: 496px;
  }
  section > * {
    aspect-ratio: 1400/496;
  }
}
section .wrapper > *:last-child {
  height: 500px;
}

dotlottie-player {
  position: absolute;
  top: 50%;
  translate: 0 -50%;
  height: 500px;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/core/components/trial/trial.component.scss"],"names":[],"mappings":"AAMA;EACE,iBAAA;AALF;AADE;EAKF;IAII,iBAAA;EAJF;EAME;IACE,sBAAA;EAJJ;AACF;AAOE;EACE,aAAA;AALJ;;AASA;EACE,kBAAA;EACA,QAAA;EACA,iBAAA;EACA,aAAA;EACA,WAAA;AANF","sourcesContent":["@mixin mu() {\r\n  @media only screen and (min-width: 1200px) {\r\n    @content;\r\n  }\r\n}\r\n\r\nsection {\r\n  min-height: 100vh;\r\n\r\n  @include mu() {\r\n    min-height: 496px;\r\n\r\n    > * {\r\n      aspect-ratio: 1400/496;\r\n    }\r\n  }\r\n\r\n  .wrapper > *:last-child {\r\n    height: 500px;\r\n  }\r\n}\r\n\r\ndotlottie-player {\r\n  position: absolute;\r\n  top: 50%;\r\n  translate: 0 -50%;\r\n  height: 500px;\r\n  width: 100%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-product-dialog',
  templateUrl: './product-dialog.component.html',
  styleUrls: ['./product-dialog.component.scss'],
})
export class ProductDialogComponent {
  @Input() isLoggedIn!: boolean;
  @Input() isRegister!: boolean;
  @Input() visible!: boolean;
  @Input() loading: boolean = false;
  @Output() nddSubmitted = new EventEmitter<string>();
  @Output() dialogClosed = new EventEmitter<void>();
  isTutorialVisible: boolean = false;

  openTutorial() {
    this.isTutorialVisible = true;
  }

  toggleForm() {
    this.isRegister = !this.isRegister;
  }

  onNddSubmitted(ndd: string) {
    this.nddSubmitted.emit(ndd);
  }

  onDialogHide() {
    this.dialogClosed.emit();
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, catchError, map, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UnsplashService {
  private accessKey = '06L4INcxuy0nVaN0ejhX03UNZqj4bxjx9OGV1Um8Mwo';
  private width = 1920;
  private categories = ['tech', 'nature', 'ecommerce'];

  private placeholderImages = [
    {
      srcSet:
        'https://images.unsplash.com/photo-1586880244386-8b3e34c8382c?w=640&auto=format&q=80 680w, https://images.unsplash.com/photo-1586880244386-8b3e34c8382c?w=1280&auto=format&q=80 1280w, https://images.unsplash.com/photo-1586880244386-8b3e34c8382c?w=1920&auto=format&q=80 1920w',
      previewImageSrc:
        'https://images.unsplash.com/photo-1586880244386-8b3e34c8382c?w=1920&auto=format&q=80',
    },
    {
      srcSet:
        'https://images.unsplash.com/photo-1586880244406-556ebe35f282?w=680&auto=format&q=80 680w, https://images.unsplash.com/photo-1586880244406-556ebe35f282?w=1280&auto=format&q=80 1280w, https://images.unsplash.com/photo-1586880244406-556ebe35f282?w=1920&auto=format&q=80 1920w',
      previewImageSrc:
        'https://images.unsplash.com/photo-1586880244406-556ebe35f282?w=1920&auto=format&q=80',
    },
    {
      srcSet:
        'https://images.unsplash.com/photo-1535957998253-26ae1ef29506?w=680&auto=format&q=80 680w, https://images.unsplash.com/photo-1535957998253-26ae1ef29506?w=1280&auto=format&q=80 1280w, https://images.unsplash.com/photo-1535957998253-26ae1ef29506?w=1920&auto=format&q=80 1920w',
      previewImageSrc:
        'https://images.unsplash.com/photo-1535957998253-26ae1ef29506?w=1920&auto=format&q=80',
    },
    {
      srcSet:
        'https://images.unsplash.com/photo-1552922750-07a7a742ce28?w=680&auto=format&q=80 680w, https://images.unsplash.com/photo-1552922750-07a7a742ce28?w=1280&auto=format&q=80 1280w, https://images.unsplash.com/photo-1552922750-07a7a742ce28?w=1920&auto=format&q=80 1920w',
      previewImageSrc:
        'https://images.unsplash.com/photo-1552922750-07a7a742ce28?w=1920&auto=format&q=80',
    },
    {
      srcSet:
        'https://images.unsplash.com/photo-1521737711867-e3b97375f902?w=680&auto=format&q=80 680w, https://images.unsplash.com/photo-1521737711867-e3b97375f902?w=1280&auto=format&q=80 1280w, https://images.unsplash.com/photo-1521737711867-e3b97375f902?w=1920&auto=format&q=80 1920w',
      previewImageSrc:
        'https://images.unsplash.com/photo-1521737711867-e3b97375f902?w=1920&auto=format&q=80',
    },
  ];

  constructor(private http: HttpClient) {}

  getRandomImages(count: number, categories: string[]): Observable<any[]> {
    const joinedCategories = categories.join(',');

    const url = `https://api.unsplash.com/photos/random?client_id=${this.accessKey}&count=${count}&orientation=portrait&query=${joinedCategories}&w=${this.width}`;
    return this.http.get<any[]>(url);
  }

  getImageByQuote(quote: string, index: number): Observable<any> {
    const joinedCategories = this.categories.join(',');

    const url = `https://api.unsplash.com/photos/random?client_id=${
      this.accessKey
    }&query=${encodeURIComponent(quote)}${joinedCategories}&w=${
      this.width
    }&orientation=portrait&count=1`;

    return this.http.get<any[]>(url).pipe(
      map((images) => images[0]),
      catchError((error) => {
        console.error('Error fetching image:', error);
        return of(this.placeholderImages[index]);
      })
    );
  }
}

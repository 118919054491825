import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DrawerService {
  private sidebarVisible = new BehaviorSubject<boolean>(false);

  toggleSidebar() {
    const newState = !this.sidebarVisible.value;
    this.sidebarVisible.next(newState);
    this.toggleBodyScroll(newState);
  }

  getSidebarVisible() {
    return this.sidebarVisible.asObservable();
  }

  toggleBodyScroll(isSidebarVisible: boolean) {
    if (isSidebarVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }
}

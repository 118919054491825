// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-form {
  box-shadow: 12px 12px 48px rgb(var(--surface-50)/50), -12px -12px 48px rgb(var(--surface-50)/50);
  max-height: 900px;
  max-width: 640px;
}
.login-form button {
  padding: 1.2rem 0;
}
.login-form button .p-button .p-button-icon-left {
  margin-right: 0em;
}
.login-form button .p-button-label {
  flex: initial;
}
.login-form > * {
  width: 100%;
}
@media only screen and (max-width: 1199px) {
  .login-form {
    box-shadow: none;
  }
  .login-form .oauth-container button {
    padding: 0.5rem 0;
  }
}
.login-form > span:before, .login-form > span:after {
  content: "";
  position: absolute;
  top: 50%;
  translate: 0 -50%;
  width: calc(50% - 1rem);
  height: 1px;
  background: #6b6b6b;
  border-radius: 8px;
}
.login-form > span:before {
  left: 0;
}
.login-form > span:after {
  right: 0;
}
.login-form [for=password] + button {
  background: none;
  right: 0.5rem;
}
.login-form .p-formgroup-signup span {
  letter-spacing: -0.6px;
}

:host ::ng-deep .oauth-container .p-button-label {
  flex: 0 0 auto;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/login-form/login-form.component.scss"],"names":[],"mappings":"AAMA;EACE,gGAAA;EAEA,iBAAA;EACA,gBAAA;AANF;AAQE;EACE,iBAAA;AANJ;AAQI;EACE,iBAAA;AANN;AASI;EACE,aAAA;AAPN;AAWE;EACE,WAAA;AATJ;AAfE;EAKF;IAuBI,gBAAA;EATF;EAYI;IACE,iBAAA;EAVN;AACF;AAeI;EAEE,WAAA;EACA,kBAAA;EACA,QAAA;EACA,iBAAA;EACA,uBAAA;EACA,WAAA;EACA,mBAAA;EACA,kBAAA;AAdN;AAiBI;EACE,OAAA;AAfN;AAkBI;EACE,QAAA;AAhBN;AAoBE;EACE,gBAAA;EACA,aAAA;AAlBJ;AAsBI;EACE,sBAAA;AApBN;;AA0BE;EACE,cAAA;AAvBJ","sourcesContent":["@mixin md() {\r\n  @media only screen and (max-width: 1199px) {\r\n    @content;\r\n  }\r\n}\r\n\r\n.login-form {\r\n  box-shadow: 12px 12px 48px rgb(var(--surface-50) / 50),\r\n    -12px -12px 48px rgb(var(--surface-50) / 50);\r\n  max-height: 900px;\r\n  max-width: 640px;\r\n\r\n  button {\r\n    padding: 1.2rem 0;\r\n\r\n    .p-button .p-button-icon-left {\r\n      margin-right: 0em;\r\n    }\r\n\r\n    .p-button-label {\r\n      flex: initial;\r\n    }\r\n  }\r\n\r\n  > * {\r\n    width: 100%;\r\n  }\r\n\r\n  @include md() {\r\n    box-shadow: none;\r\n\r\n    .oauth-container {\r\n      button {\r\n        padding: 0.5rem 0;\r\n      }\r\n    }\r\n  }\r\n\r\n  > span {\r\n    &:before,\r\n    &:after {\r\n      content: \"\";\r\n      position: absolute;\r\n      top: 50%;\r\n      translate: 0 -50%;\r\n      width: calc(50% - 1rem);\r\n      height: 1px;\r\n      background: #6b6b6b;\r\n      border-radius: 8px;\r\n    }\r\n\r\n    &:before {\r\n      left: 0;\r\n    }\r\n\r\n    &:after {\r\n      right: 0;\r\n    }\r\n  }\r\n\r\n  [for=\"password\"] + button {\r\n    background: none;\r\n    right: 0.5rem;\r\n  }\r\n\r\n  .p-formgroup-signup {\r\n    span {\r\n      letter-spacing: -0.6px;\r\n    }\r\n  }\r\n}\r\n\r\n:host ::ng-deep {\r\n  .oauth-container .p-button-label {\r\n    flex: 0 0 auto;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-trial',
  templateUrl: './trial.component.html',
  styleUrls: ['./trial.component.scss'],
})
export class TrialComponent {
  private scriptUrl =
    'https://unpkg.com/@dotlottie/player-component@latest/dist/dotlottie-player.mjs';

  constructor() {}

  ngOnInit() {
    this.loadScript(this.scriptUrl);
  }

  private loadScript(src: string) {
    const script = document.createElement('script');
    script.type = 'module';
    script.src = src;
    script.async = true;
    document.body.appendChild(script);
  }
}

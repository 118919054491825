import { Component } from '@angular/core';
import { DrawerService } from '../../services/drawer.service';

@Component({
  selector: 'app-not-logged-drawer',
  templateUrl: './not-logged-drawer.component.html',
  styleUrls: ['./not-logged-drawer.component.scss'],
})
export class NotLoggedDrawerComponent {
  constructor(private drawerService: DrawerService) {}

  toggleSidebar() {
    this.drawerService.toggleSidebar();
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-product-header',
  templateUrl: './product-header.component.html',
  styleUrls: ['./product-header.component.scss'],
})
export class ProductHeaderComponent {
  @Input() selectedPlanIndex!: number;
  @Output() planChange = new EventEmitter<number>();

  emitChange(planIndex: number): void {
    this.planChange.emit(planIndex);
  }
}

import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { register } from 'swiper/element/bundle';
import { PrimeNGConfig } from 'primeng/api';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ToastService } from './core/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { TrackingService } from './shared/services/tracking.service';
import Intercom from '@intercom/messenger-js-sdk';

register();

Intercom({
  app_id: 'f51mooij'
});

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  private mutationObserver!: MutationObserver;
  private navigationSubscription!: Subscription;
  title = 'angular-phantom';
  showFooter = true;
  showHeader = true;
  userData: any;
  productsData: any;
  packsData: any;
  loading1 = false;
  loading2 = false;
  emailFormCount: number = 0;
  timer: number = 0;

  constructor(
    private primengConfig: PrimeNGConfig,
    private router: Router,
    private toastService: ToastService,
    private translate: TranslateService,
    private trackingService: TrackingService,
    private renderer: Renderer2,
    private el: ElementRef
  ) {
    this.translate.setDefaultLang('fr');

    this.navigationSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        //window.Intercom('update');
        this.showFooter = !(
          event.url.startsWith('/dashboard') ||
          event.url.startsWith('/login') ||
          event.url.startsWith('/register')
        );

        this.showHeader = !event.url.startsWith(
          '/dashboard/account/success-checkout'
        );

        if (event.url !== '/') {
          document.body.classList.remove('index-page');
        }
      }
    });
  }

  ngOnInit() {
    this.primengConfig.ripple = true;

    this.toastService.loading1$.subscribe((loading) => {
      this.loading1 = loading;
    });

    this.toastService.loading2$.subscribe((loading) => {
      this.loading2 = loading;
    });

    if (this.router.url === '/') {
      document.body.classList.add('index-page');

      const link = document.createElement('link');
      link.rel = 'preload';
      link.as = 'image';
      link.href =
        'https://cdn.shopify.com/s/files/1/0532/4226/5770/files/Copie_de_Colorful_Neon_Modern_Gaming_Channel_YouTube_Channel_Art.jpg?v=1677101671&width=532';
      document.head.appendChild(link);
    }

    this.setTrackingCountdown();
    this.initMutationObserver();
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }

    if (this.mutationObserver) {
      this.mutationObserver.disconnect();
    }
  }

  private initMutationObserver() {
    const config = { childList: true, subtree: true };

    this.mutationObserver = new MutationObserver((mutationsList, observer) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          mutation.addedNodes.forEach((node) => {
            if (node.nodeType === 1) {
              const element = node as HTMLElement;
              const klaviyoForm = element.querySelector('form.klaviyo-form');

              if (klaviyoForm) {
                this.trackFormSubmission(klaviyoForm as HTMLFormElement);
                this.emailFormCount++;

                if (this.emailFormCount === 2) {
                  this.mutationObserver.disconnect();
                }
              }
            }
          });
        }
      }
    });

    this.mutationObserver.observe(document.body, config);
  }

  private trackFormSubmission(formElement: HTMLFormElement): void {
    this.renderer.listen(formElement, 'submit', (event) => {
      if (event.type === 'error') {
        console.error('Form error!', event);
      }

      this.setTrackingLead();
    });
  }

  setTrackingLead() {
    this.trackingService.sendAppData({
      event_name: 'Lead',
    });
  }

  setTrackingCountdown() {
    this.timer = 0;
    const counter = setInterval(() => {
      this.timer++;

      if (this.timer === 30) {
        this.trackingService
          .sendAppData({
            event_name: '30sec',
          })
          .subscribe();
      } else if (this.timer === 60) {
        this.trackingService
          .sendAppData({
            event_name: '1min',
          })
          .subscribe();
      } else if (this.timer === 90) {
        this.trackingService
          .sendAppData({
            event_name: '1min30',
          })
          .subscribe();

        clearInterval(counter);
      }
    }, 1000);
  }
}

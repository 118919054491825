// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep .p-sidebar-footer {
  padding: 0 !important;
}
:host ::ng-deep .p-menuitem-icon {
  min-width: 16px;
}
:host ::ng-deep .p-sidebar-content {
  padding-inline: 0 !important;
  padding-top: 0 !important;
}
:host ::ng-deep .p-button-icon {
  min-width: 14px;
}
:host ::ng-deep #profile-menu .p-submenu-list {
  right: 0;
  min-width: 235px;
}

.profile-dropdown-container .profile-dropdown a {
  min-width: 54px;
}`, "",{"version":3,"sources":["webpack://./src/app/core/components/header/header.component.scss"],"names":[],"mappings":"AACE;EACE,qBAAA;AAAJ;AAGE;EACE,eAAA;AADJ;AAIE;EACE,4BAAA;EACA,yBAAA;AAFJ;AAKE;EACE,eAAA;AAHJ;AAME;EACE,QAAA;EACA,gBAAA;AAJJ;;AAQA;EACE,eAAA;AALF","sourcesContent":[":host ::ng-deep {\r\n  .p-sidebar-footer {\r\n    padding: 0 !important;\r\n  }\r\n\r\n  .p-menuitem-icon {\r\n    min-width: 16px;\r\n  }\r\n\r\n  .p-sidebar-content {\r\n    padding-inline: 0 !important;\r\n    padding-top: 0 !important;\r\n  }\r\n\r\n  .p-button-icon {\r\n    min-width: 14px;\r\n  }\r\n\r\n  #profile-menu .p-submenu-list {\r\n    right: 0;\r\n    min-width: 235px;\r\n  }\r\n}\r\n\r\n.profile-dropdown-container .profile-dropdown a {\r\n  min-width: 54px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

// auth.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private isLoggedIn = new BehaviorSubject<boolean>(this.hasToken());
  private secretKey = environment.secretKey;

  private hasToken(): boolean {
    return !!localStorage.getItem('access_token');
  }

  get isLoggedIn$() {
    return this.isLoggedIn.asObservable();
  }

  removeAuthToken() {
    localStorage.removeItem('access_token');
    this.isLoggedIn.next(false);
  }

  encrypt(text: string): string {
    return 'EZ4x8zzc4' + CryptoJS.AES.encrypt(text, this.secretKey).toString();
  }

  decrypt(text: string): string {
    if (!text.startsWith('EZ4x8zzc4')) {
      return '';
    }

    const bytes = CryptoJS.AES.decrypt(
      text.replace('EZ4x8zzc4', ''),
      this.secretKey
    );
    return decodeURI(bytes.toString(CryptoJS.enc.Utf8));
  }

  getLoggedInValue() {
    return this.isLoggedIn.getValue();
  }

  getAuthToken(): string | null {
    return localStorage.getItem('access_token');
  }

  setAuthToken(token: string) {
    localStorage.setItem('access_token', token);
    this.isLoggedIn.next(true);
  }

  clearAuthToken() {
    localStorage.removeItem('access_token');
    this.isLoggedIn.next(false);
  }
}

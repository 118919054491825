import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class QuoteService {
  private quotes: any[] = this.translate.instant('quotes.random_quotes');

  constructor(private translate: TranslateService) {}

  getRandomQuotes(count: number): Observable<any[]> {
    return new Observable((observer) => {
      if (this.quotes && this.quotes.length > 0) {
        let randomQuotes = [];
        for (let i = 0; i < count; i++) {
          const randomIndex = Math.floor(Math.random() * this.quotes.length);
          randomQuotes.push(this.quotes[randomIndex]);
        }
        observer.next(randomQuotes);
      } else {
        observer.next([]);
      }
      observer.complete();
    });
  }
}

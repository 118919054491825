// shared.module.ts
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { LoginSliderUnsplashComponent } from './components/login-slider-unsplash/login-slider-unsplash.component';
import { YoutubeLastVideosCarouselComponent } from './components/youtube-last-videos-carousel/youtube-last-videos-carousel.component';
import { ProductsComponent } from './components/products/products.component';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { RegisterFormComponent } from './components/register-form/register-form.component';
import { NddFormComponent } from './components/ndd-form/ndd-form.component';
import { ProductHeaderComponent } from './components/products/components/product-header/product-header.component';
import { ProductDialogComponent } from './components/products/components/product-dialog/product-dialog.component';

import { CardModule } from 'primeng/card';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { CheckboxModule } from 'primeng/checkbox';
import { TooltipModule } from 'primeng/tooltip';
import { RouterModule } from '@angular/router';
import { ToastModule } from 'primeng/toast';
import { TranslateModule } from '@ngx-translate/core';

import {
  RECAPTCHA_V3_SITE_KEY,
  RecaptchaModule,
  RecaptchaV3Module,
} from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
import { SkeletonModule } from 'primeng/skeleton';

@NgModule({
  declarations: [
    LoginSliderUnsplashComponent,
    YoutubeLastVideosCarouselComponent,
    ProductsComponent,
    LoginFormComponent,
    RegisterFormComponent,
    NddFormComponent,
    ProductHeaderComponent,
    ProductDialogComponent,
  ],
  imports: [
    CommonModule,
    CardModule,
    BadgeModule,
    ButtonModule,
    ReactiveFormsModule,
    TooltipModule,
    InputTextModule,
    SkeletonModule,
    CheckboxModule,
    RouterModule,
    DialogModule,
    FormsModule,
    ToastModule,
    TranslateModule,
    RecaptchaModule,
    RecaptchaV3Module,
  ],
  exports: [
    LoginSliderUnsplashComponent,
    YoutubeLastVideosCarouselComponent,
    ProductsComponent,
    LoginFormComponent,
    RegisterFormComponent,
    NddFormComponent,
    TranslateModule,
    RecaptchaModule,
    RecaptchaV3Module,
  ],
  providers: [
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.reCaptchaKey,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep .btn--gradient {
  background: linear-gradient(92.78deg, #261826, #352513 30%, #452d0d 60%, #906507 100%) !important;
  border: 0 !important;
  position: relative;
  overflow: visible;
}
:host ::ng-deep .btn--gradient:after {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: inherit;
  opacity: 0;
  box-shadow: 0 0 16px 4px rgba(179, 142, 116, 0.4), 0 0 6px 1px rgba(163, 117, 16, 0.5), inset 0 0 0 1px rgba(69, 88, 255, 0.08);
  transition: opacity 0.15s ease;
  z-index: 1;
}
:host ::ng-deep .btn--gradient:hover:not(:disabled):after {
  opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AACE;EACE,iGAAA;EAOA,oBAAA;EACA,kBAAA;EACA,iBAAA;AANJ;AAQI;EACE,WAAA;EACA,kBAAA;EACA,QAAA;EACA,sBAAA;EACA,UAAA;EACA,+HAAA;EAGA,8BAAA;EACA,UAAA;AARN;AAWI;EACE,UAAA;AATN","sourcesContent":[":host ::ng-deep {\r\n  .btn--gradient {\r\n    background: linear-gradient(\r\n      92.78deg,\r\n      #261826,\r\n      #352513 30%,\r\n      #452d0d 60%,\r\n      #906507 100%\r\n    ) !important;\r\n    border: 0 !important;\r\n    position: relative;\r\n    overflow: visible;\r\n\r\n    &:after {\r\n      content: \"\";\r\n      position: absolute;\r\n      inset: 0;\r\n      border-radius: inherit;\r\n      opacity: 0;\r\n      box-shadow: 0 0 16px 4px rgba(179, 142, 116, 0.4),\r\n        0 0 6px 1px rgba(163, 117, 16, 0.5),\r\n        inset 0 0 0 1px rgba(69, 88, 255, 0.08);\r\n      transition: opacity 0.15s ease;\r\n      z-index: 1;\r\n    }\r\n\r\n    &:hover:not(:disabled):after {\r\n      opacity: 1;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/core/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastService } from 'src/app/core/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent implements OnInit {
  loginRegisterTranslations: any = this.translate.instant('login_register');
  @Input() isPopup: boolean = false;
  @Output() popupTriggered = new EventEmitter<void>();
  hidePassword: boolean = true;
  password: string = '';
  rememberMe: boolean = false;
  isLoading: boolean = false;
  loginForm!: FormGroup;
  resetForm!: FormGroup;
  resetLoading: boolean = false;
  private apiUrl = environment.apiUrl;

  visible: boolean = false;

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute,
    private toastService: ToastService,
    private translate: TranslateService,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
      rememberMe: [true],
    });

    this.resetForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  triggerPopup() {
    this.popupTriggered.emit();
  }

  togglePasswordVisibility() {
    this.hidePassword = !this.hidePassword;
  }

  hideDialog() {
    this.visible = false;
  }

  showDialog() {
    this.visible = true;
  }

  onSubmit() {
    this.isLoading = true;
    this.http
      .post<any>(this.apiUrl + '/api/login', this.loginForm.value)
      .subscribe(
        (data) => {
          if (data && data.token) {
            this.login(data.token);
          } else {
            console.error(data);
          }

          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
          if (error.status === 401) {
            this.toastService.showToast({
              severity: 'error',
              summary: this.translate.instant('toast.error'),
              detail: this.loginRegisterTranslations.error_login.detail_form,
            });
          }
        },
      );
  }

  onResetSubmit() {
    this.resetLoading = true;
    this.http
      .post<any>(this.apiUrl + '/request_password', this.resetForm.value)
      .subscribe(
        (data) => {
          this.toastService.showToast({
            severity: 'success',
            summary: this.translate.instant('toast.success'),
            detail:
              this.loginRegisterTranslations.request_reset_password.success,
          });

          this.resetLoading = false;
          this.hideDialog();
        },
        (error) => {
          this.resetLoading = false;
          if (error.status === 401) {
            this.toastService.showToast({
              severity: 'error',
              summary: this.translate.instant('toast.error'),
              detail:
                this.loginRegisterTranslations.request_reset_password.error
                  .detail,
              showButton1:
                this.loginRegisterTranslations.request_reset_password.error.cta,
              callbackFunction1: () => {
                window.open('mailto:support@phantom-theme.fr');
              },
            });
          }
        },
      );
  }

  loginWithGoogle(type: 'google' | 'facebook') {
    const url = `${this.apiUrl}/${type}`;
    this.isLoading = true;

    this.http.get<any>(url, { withCredentials: true }).subscribe(
      (data) => {
        if (data) {
          const popup = window.open(data, '_blank', 'width=500,height=600');

          const popupCheckInterval = setInterval(() => {
            if (popup && popup.closed) {
              clearInterval(popupCheckInterval);
              this.isLoading = false;
            }
          }, 1000);

          window.addEventListener('message', (event) => {
            if (event.origin !== this.apiUrl) {
              return;
            }

            const data = event.data;

            if (data && data.token) {
              this.login(data.token);
              this.isLoading = false;
            }

            if (popup) {
              popup.close();
              this.isLoading = false;
            }
          });
        }
      },
      (error) => {
        this.toastService.showToast({
          severity: 'error',
          summary: this.translate.instant('toast.error'),
          detail: this.loginRegisterTranslations.error_login.detail_google,
        });
        this.isLoading = false;
      },
    );
  }

  async login(token: string) {
    this.authService.setAuthToken(token);
    localStorage.setItem('access_token', token);
    await this.userService.fetchUserData();

    if (!this.isPopup) {
      this.route.queryParams.subscribe((params) => {
        if (params['billing_portal'] === 'true') {
          this.createBillingPortalSession();
          return;
        }

        const returnUrl = params['returnUrl'];

        if (!returnUrl) {
          this.router.navigate(['/dashboard/account/licenses']);
          return;
        }

        const decryptedUrl = this.authService.decrypt(params['returnUrl']);

        if (!this.isValidUrl(decryptedUrl)) {
          this.router.navigate(['/dashboard/account/licenses']);
        }

        const urlParts = decryptedUrl.split('?');
        const path = urlParts[0];
        const queryParams = urlParts[1] || '';

        const queryParamsParts = queryParams.split('&');
        const queryParamsObj: Record<string, string> = {};

        queryParamsParts.forEach((param) => {
          const [key, value] = param.split('=');
          if (key && value) {
            queryParamsObj[key] = value;
          }
        });

        this.router.navigate([path], { queryParams: queryParamsObj });
      });
    }
  }

  createBillingPortalSession() {
    this.http.post(`${environment.apiUrl}/api/billing_portal`, {}).subscribe(
      (response: any) => {
        window.location.href = response.url;
      },
      (error) => {
        this.toastService.showToast({
          severity: 'error',
          summary: this.translate.instant('toast.error'),
          detail: this.translate.instant(
            'dashboard_account.sidebar.error_create_session_detail',
          ),
        });
      },
    );
  }

  isValidUrl(input: string): boolean {
    return /^https?:\/\/[^\s/$.?#].[^\s]*$/.test(input);
  }
}

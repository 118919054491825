import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-ndd-form',
  templateUrl: './ndd-form.component.html',
  styleUrls: ['./ndd-form.component.scss'],
})
export class NddFormComponent implements OnInit {
  form!: FormGroup;
  @Input() loading: boolean = false;
  @Output() openTutorialEmit = new EventEmitter<boolean>();
  @Output() formSubmitted = new EventEmitter<string>();

  ngOnInit(): void {
    const urlPattern = '^https?://admin.shopify.com/store/[A-Za-z0-9-]+/?$';

    this.form = new FormGroup({
      ndd: new FormControl('', [
        Validators.required,
        Validators.pattern(urlPattern),
      ]),
    });
  }

  openTutorial() {
    this.openTutorialEmit.emit(true);
  }

  nddFormSubmit() {
    if (this.form.valid) {
      this.formSubmitted.emit(this.form.value.ndd);
    }
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, catchError, tap, throwError } from 'rxjs';
import { AuthService } from './auth.service';
import { Form } from '@angular/forms';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class UserService {
  private user = new BehaviorSubject<any>(null);
  private apiUrl = environment.apiUrl;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private router: Router
  ) {
    if (this.authService.getLoggedInValue()) {
      this.fetchUserData();
    } else {
      this.user.next(null);
    }
  }

  fetchUserData() {
    this.http
      .get<any>(this.apiUrl + '/dashboard/api/user', { withCredentials: true })
      .pipe(
        catchError((error) => this.handleError(error)),
        tap((userData) => {
          this.user.next(userData);
        })
      )
      .subscribe();
  }

  clearUserData() {
    this.user.next(null);
  }

  changePassword(pwdForm: Form) {
    return this.http
      .post<any>(this.apiUrl + '/dashboard/api/change_password', pwdForm, {
        withCredentials: true,
      })
      .pipe(catchError((error) => this.handleError(error)));
  }

  isLoggedIn(): boolean {
    return !!this.user;
  }

  getUserData(): Observable<any> {
    return this.user.asObservable();
  }

  setUserActive() {
    const currentUser = this.user.getValue();
    currentUser.active = true;
    this.user.next(currentUser);
  }

  setUserData(form: FormData) {
    return this.http
      .post<any>(this.apiUrl + '/dashboard/api/user', form, {
        withCredentials: true,
      })
      .pipe(catchError((error) => this.handleError(error)));
  }

  sendActivationLink() {
    return this.http
      .get<any>(this.apiUrl + '/request_account_activation', {
        withCredentials: true,
      })
      .pipe(catchError((error) => this.handleError(error)));
  }

  deleteGoogleEmail() {
    return this.http
      .delete<any>(this.apiUrl + '/dashboard/api/google_id', {
        withCredentials: true,
      })
      .pipe(catchError((error) => this.handleError(error)));
  }

  createBillingPortalSession() {
    return this.http
      .post(`${environment.apiUrl}/api/billing_portal`, null)
      .pipe(
        catchError((error) =>
          this.handleError(
            error,
            'Erreur lors de la création de la session de facturation'
          )
        )
      );
  }

  hasAdminRole(): boolean {
    const currentUser = this.user.getValue();
    return currentUser?.roles?.includes('ROLE_ADMIN');
  }

  private handleError(
    error: HttpErrorResponse,
    message: string = 'Erreur lors de la récupération des données utilisateur'
  ) {
    if (
      error.error.detail ===
      'Warning: Header may not contain more than a single header, new line detected'
    ) {
      this.authService.clearAuthToken();
      this.clearUserData();
      console.clear();
      this.router.navigate(['/login']);
    }

    return throwError(() => new Error(message));
  }
}

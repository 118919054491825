// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep .p-dialog .p-dialog-header {
  background: var(--surface-background);
}
:host ::ng-deep code {
  word-break: break-word;
}
@media only screen and (max-width: 1199px) {
  :host ::ng-deep .p-dialog:not(.p-dialo-maximized).p-element {
    width: min(90vw, 520px) !important;
  }
}
:host ::ng-deep p-dialog:not(.logged-in) .p-dialog-header {
  padding: 0;
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 1;
  background: transparent;
}
:host ::ng-deep p-dialog:not(.logged-in) .p-dialog {
  position: relative;
  border-radius: 16px;
  overflow: hidden;
}
:host ::ng-deep p-dialog:not(.logged-in) .p-dialog-content {
  padding: 0;
}
:host ::ng-deep p-dialog:not(.logged-in) .p-dialog-content > .max-w-30rem {
  max-width: initial !important;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/products/components/product-dialog/product-dialog.component.scss"],"names":[],"mappings":"AAaE;EACE,qCAAA;AAZJ;AAeE;EACE,sBAAA;AAbJ;AAJE;EAqBE;IACE,kCAAA;EAdJ;AACF;AAkBI;EACE,UAAA;EACA,kBAAA;EACA,SAAA;EACA,WAAA;EACA,UAAA;EACA,uBAAA;AAhBN;AAmBI;EACE,kBAAA;EACA,mBAAA;EACA,gBAAA;AAjBN;AAoBI;EACE,UAAA;AAlBN;AAoBM;EACE,6BAAA;AAlBR","sourcesContent":["@mixin md() {\r\n  @media only screen and (max-width: 1199px) {\r\n    @content;\r\n  }\r\n}\r\n\r\n@mixin mu() {\r\n  @media only screen and (min-width: 1200px) {\r\n    @content;\r\n  }\r\n}\r\n\r\n:host ::ng-deep {\r\n  .p-dialog .p-dialog-header {\r\n    background: var(--surface-background);\r\n  }\r\n\r\n  code {\r\n    word-break: break-word;\r\n  }\r\n\r\n  @include md() {\r\n    .p-dialog:not(.p-dialo-maximized).p-element {\r\n      width: min(90vw, 520px) !important;\r\n    }\r\n  }\r\n\r\n  p-dialog:not(.logged-in) {\r\n    .p-dialog-header {\r\n      padding: 0;\r\n      position: absolute;\r\n      top: 1rem;\r\n      right: 1rem;\r\n      z-index: 1;\r\n      background: transparent;\r\n    }\r\n\r\n    .p-dialog {\r\n      position: relative;\r\n      border-radius: 16px;\r\n      overflow: hidden;\r\n    }\r\n\r\n    .p-dialog-content {\r\n      padding: 0;\r\n\r\n      > .max-w-30rem {\r\n        max-width: initial !important;\r\n      }\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

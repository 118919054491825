import {
  Component,
  Input,
  OnInit,
  ElementRef,
  Renderer2,
  HostListener,
  AfterViewInit,
} from '@angular/core';
import { Image } from '../../models/image.model';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
})
export class ImageComponent implements OnInit, AfterViewInit {
  @Input() image!: Image;

  constructor(
    private el: ElementRef,
    private http: HttpClient,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    if (this.image.preload) {
      this.preloadImage();
    }

    this.loadImageDimensions(this.image.url).then((dimensions) => {
      this.image.w = dimensions.width;
      this.image.h = dimensions.height;
      this.image.class = this.image.crop
        ? `crop-${this.image.crop}${
            this.image.class ? ' ' + this.image.class : ''
          }`
        : '';
      this.setSrcset();
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.calculateSizes();
    }, 200);
  }

  getCustomStyle(): { [key: string]: string } {
    return {
      '--image-small': `url(${this.image.url}&width=10)`,
    };
  }

  preloadImage() {
    if (document.querySelector('#preloadImage')) {
      (document.querySelector('#preloadImage') as HTMLLinkElement).href =
        this.image.url;
    } else {
      const link = document.createElement('link');
      link.rel = 'preload';
      link.id = 'preloadImage';
      link.as = 'image';
      link.href = this.image.url;
      document.head.appendChild(link);
    }
  }

  calculateSizes() {
    const container = this.el.nativeElement.querySelector('img');
    if (container) {
      const containerWidth = container.clientWidth;
      if (containerWidth > 0) {
        this.image.sizes = `${containerWidth}px`;
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.calculateSizes();
  }

  loadImageDimensions(url: string): Promise<{ width: number; height: number }> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        this.addClassToContainer('loaded');

        this.el.nativeElement
          .querySelector('.image-container')
          .style.setProperty(
            '--padding-bottom',
            `${(100 * (img.height ?? 1)) / (img.width ?? 1)}%`
          );
        resolve({ width: img.width, height: img.height });
      };
      img.onerror = reject;
      img.src = url;
    });
  }

  setSrcset() {
    if (this.image.breakpoints.length > 0) {
      this.image.srcset = this.image.breakpoints
        .map((breakpoint) => {
          const width = parseInt(breakpoint);
          return `${this.image.url}&width=${width} ${width}w`;
        })
        .join(', ');
    }
  }

  addClassToContainer(className: string) {
    const container = this.el.nativeElement.querySelector('.image-container');
    if (container) {
      this.renderer.addClass(container, className);
    }
  }

  parseStyle(styleString: string): { [key: string]: string } {
    return styleString
      .split(';')
      .filter((style) => style)
      .map((style) => style.split(':'))
      .reduce((styleObj, style) => {
        const [property, value] = style;
        styleObj[property.trim()] = value.trim();
        return styleObj;
      }, {} as { [key: string]: string });
  }
}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { QuoteService } from '../../services/random-quote.service';
import { UnsplashService } from '../../services/unsplash-images.service';
import Swiper from 'swiper';
import { TranslateService } from '@ngx-translate/core';
import { Observable, forkJoin, map, switchMap } from 'rxjs';

interface Quote {
  quote: string;
  author: string;
}

@Component({
  selector: 'app-login-slider-unsplash',
  templateUrl: './login-slider-unsplash.component.html',
  styleUrls: ['./login-slider-unsplash.component.scss'],
})
export class LoginSliderUnsplashComponent implements OnInit, OnDestroy {
  @Input() images!: any[];
  @Input() citation!: { quote: string; author: string };
  private swiperInstance?: Swiper;
  private swiperElement?: HTMLElement;
  private quoteTranslations: any;
  citations: Array<Observable<{ quote: string; author: string }>> = [];

  constructor(
    private unsplashService: UnsplashService,
    private quoteService: QuoteService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.translate.get('quote').subscribe((translations: any) => {
      this.quoteTranslations = translations;

      this.getImagesByQuotes();
    });
  }

  getImagesByQuotes() {
    this.quoteService
      .getRandomQuotes(5)
      .pipe(
        switchMap((quotes) => {
          const imageRequests = quotes.map((quote, index) =>
            this.unsplashService.getImageByQuote(quote.quote, index).pipe(
              map((img) => ({
                smallSrc: img.urls
                  ? img.urls.small.replace('&w=500', '&w=10')
                  : img.previewImageSrc,
                srcSet: img.urls
                  ? `${img.urls.small} 640w, ${img.urls.regular} 1280w, ${img.urls.full} 1920w`
                  : img.srcSet,
                previewImageSrc: img.urls ? img.urls.full : img.previewImageSrc,
                alt: img.alt_description,
                title: img.description || img.alt_description,
                citation: quote,
                url: img.links ? img.links.html : '',
                author: img.user ? img.user.name : '',
                from: img.user ? img.user.links.html : '',
              }))
            )
          );
          return forkJoin(imageRequests);
        })
      )
      .subscribe(
        (images) => {
          this.images = images;
          this.waitSlide();
        },
        (error) => console.error('Error fetching images from Unsplash:', error)
      );
  }

  getImages() {
    const categories = ['tech', 'nature', 'ecommerce'];
    const quotesObservable = this.quoteService.getRandomQuotes(5);
    const imageObservable = this.quoteService.getRandomQuotes(5);

    forkJoin([imageObservable, quotesObservable]).subscribe(
      ([images, quotes]) => {
        this.images = images.map((img, index) => ({
          smallSrc: img.urls.small.replace('&w=500', '&w=10'),
          srcSet: `${img.urls.small} 640w, ${img.urls.regular} 1280w, ${img.urls.full} 1920w`,
          previewImageSrc: img.urls.full,
          alt: img.alt_description,
          title: img.description || img.alt_description,
          citation: quotes[index],
          url: img.links.html,
          author: img.user.name,
          from: img.user.links.html,
        }));

        this.waitSlide();
      },
      (error) => {
        console.error('Error fetching images from Unsplash:', error);
        quotesObservable.subscribe((quotes) => {
          this.images = [
            {
              srcSet:
                'https://images.unsplash.com/photo-1586880244386-8b3e34c8382c?w=640&auto=format&q=80 680w, https://images.unsplash.com/photo-1586880244386-8b3e34c8382c?w=1280&auto=format&q=80 1280w, https://images.unsplash.com/photo-1586880244386-8b3e34c8382c?w=1920&auto=format&q=80 1920w',
              previewImageSrc:
                'https://images.unsplash.com/photo-1586880244386-8b3e34c8382c?w=1920&auto=format&q=80',
              alt: this.quoteTranslations['1'],
              title: this.quoteTranslations['1'],
              citation: quotes[0],
            },
            {
              srcSet:
                'https://plus.unsplash.com/premium_photo-1684785618727-378a3a5e91c5?w=680&auto=format&q=80 680w, https://plus.unsplash.com/premium_photo-1684785618727-378a3a5e91c5?w=1280&auto=format&q=80 1280w, https://plus.unsplash.com/premium_photo-1684785618727-378a3a5e91c5?w=1920&auto=format&q=80 1920w',
              previewImageSrc:
                'https://plus.unsplash.com/premium_photo-1684785618727-378a3a5e91c5?w=1920&auto=format&q=80',
              alt: this.quoteTranslations['2'],
              title: this.quoteTranslations['2'],
              citation: quotes[1],
            },
            {
              srcSet:
                'https://plus.unsplash.com/premium_photo-1683887064374-dc6dd77ece50?w=680&auto=format&q=80 680w, https://plus.unsplash.com/premium_photo-1683887064374-dc6dd77ece50?w=1280&auto=format&q=80 1280w, https://plus.unsplash.com/premium_photo-1683887064374-dc6dd77ece50?w=1920&auto=format&q=80 1920w',
              previewImageSrc:
                'https://plus.unsplash.com/premium_photo-1683887064374-dc6dd77ece50?w=1920&auto=format&q=80',
              alt: this.quoteTranslations['3'],
              title: this.quoteTranslations['3'],
              citation: quotes[2],
            },
          ];

          this.waitSlide();
        });
      }
    );
  }

  private waitSlide(): void {
    this.swiperElement = document.querySelector(
      '.swiper-container'
    ) as HTMLElement;

    const x = setInterval(() => {
      if (
        this.swiperElement &&
        this.swiperElement.querySelectorAll('.swiper-slide').length > 0
      ) {
        clearInterval(x);
        this.initialiserSwiper();
      }
    }, 100);
  }

  private initialiserSwiper(): void {
    if (
      this.swiperElement &&
      (!this.swiperInstance || this.swiperInstance.destroyed)
    ) {
      const progressCircle = document.querySelector('.autoplay-progress svg');
      const progressContent = document.querySelector('.autoplay-progress span');

      var self = this;
      this.swiperInstance = new Swiper(this.swiperElement, {
        loop: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        slidesPerView: 1,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        on: {
          autoplayTimeLeft(s, time, progress) {
            // Cast progressCircle et progressContent en HTMLElement
            const progressCircleElement = progressCircle as HTMLElement;
            const progressContentElement = progressContent as HTMLElement;

            // Vérifier si progressCircle et progressContent ne sont pas null
            if (progressCircleElement && progressContentElement) {
              progressCircleElement.style.setProperty(
                '--progress',
                (1 - progress).toString()
              );
              progressContentElement.textContent = `${Math.ceil(time / 1000)}`;
            }
          },
          resize() {
            self.swiperInstance?.update();
          },
        },
      });
    }
  }

  ngOnDestroy(): void {
    if (this.swiperInstance && !this.swiperInstance.destroyed) {
      this.swiperInstance.destroy(true, true);
      this.swiperInstance = undefined;
      this.swiperElement = undefined;
    }
  }
}

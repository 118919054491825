import { Component, OnInit } from '@angular/core';
import { SubscriptionsService } from '../../services/subscriptions.service';

interface MenuItem {
  title: string;
  routerLink?: string;
  url?: string;
  icon?: string;
}

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  isClient = false;
  questions: MenuItem[] = [];
  policies: MenuItem[] = [];
  videos: MenuItem[] = [];
  informations: MenuItem[] = [];
  socialNetworks: MenuItem[] = [];

  constructor(private subscriptionsService: SubscriptionsService) {}

  ngOnInit(): void {
    this.subscriptionsService.getSubscriptionsData().subscribe((data) => {
      if (data && data.length > 0) {
        this.isClient = true;
      }
    });
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private baseUrl = 'http://your-backend-api.com';
  private hashKey: string = environment.hashKey;

  constructor(private http: HttpClient) {}

  sendSignedRequest(endpoint: string, data: any) {
    const signature = this.generateSignature(data);

    const headers = new HttpHeaders({
      'X-Signature': signature,
    });

    return this.http.post(`${this.baseUrl}/${endpoint}`, data, { headers });
  }

  generateSignature(data: any): string {
    const jsonData = JSON.stringify(data);

    const signature = CryptoJS.HmacSHA256(jsonData, this.hashKey).toString();

    return signature;
  }
}

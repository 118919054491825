// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input.ng-dirty.ng-invalid {
  border-color: #ff9a9a;
}

.separator {
  height: 1px;
}

code {
  background: var(--surface-50);
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/ndd-form/ndd-form.component.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,6BAAA;AACF","sourcesContent":["input.ng-dirty.ng-invalid {\r\n  border-color: #ff9a9a;\r\n}\r\n\r\n.separator {\r\n  height: 1px;\r\n}\r\n\r\ncode {\r\n  background: var(--surface-50);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

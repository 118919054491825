import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Product, Pack, MergedProduct, Price } from '../models/product.model';
import {
  BehaviorSubject,
  Observable,
  catchError,
  forkJoin,
  throwError,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  private mergedProducts = new BehaviorSubject<MergedProduct[]>([]);
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {
    this.fetchProductsData();
  }

  fetchProductsData() {
    const productsRequest = this.http
      .get<Product[]>(this.apiUrl + '/api/products', { withCredentials: true })
      .pipe(catchError(this.handleError));

    const packsRequest = this.http
      .get<Pack[]>(this.apiUrl + '/api/packs', { withCredentials: true })
      .pipe(catchError(this.handleError));

    forkJoin([productsRequest, packsRequest]).subscribe(([products, packs]) => {
      const mergedData = this.mergeProductsAndPacks(products, packs);
      this.mergedProducts.next(mergedData);
    });
  }

  mergeProductsAndPacks(products: Product[], packs: Pack[]): MergedProduct[] {
    return products.map((product) => {
      const correspondingPack = packs.find(
        (pack) => pack.name === product.name
      );

      return {
        id: correspondingPack?.id || 0,
        name: product.name,
        version: correspondingPack?.version || '',
        drive: correspondingPack?.drive || '',
        thumbnail: correspondingPack?.thumbnail || '',
        demos: correspondingPack?.demos || [],
        prices: this.setProductPrices(product.prices),
      };
    });
  }

  setProductPrices(prices: Price[]): Price[] {
    let newPrices = prices.map((price) => {
      let pricePerMonth = price.unitAmount;

      if (price.interval === 'year') {
        pricePerMonth = price.unitAmount / 12 / 100;
      } else if (price.interval === 'month') {
        pricePerMonth = price.unitAmount / price.intervalCount / 100;
      }

      return {
        ...price,
        pricePerMonth: pricePerMonth,
      };
    });

    return newPrices.sort((a, b) => {
      const monthsA =
        a.interval === 'month' ? a.intervalCount : a.intervalCount * 12;
      const monthsB =
        b.interval === 'month' ? b.intervalCount : b.intervalCount * 12;

      return monthsA - monthsB;
    });
  }

  getProductsData(): Observable<any> {
    return this.mergedProducts.asObservable();
  }

  private handleError(error: HttpErrorResponse) {
    let errorMessage = "Une erreur inconnue s'est produite";
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Une erreur est survenue: ${error.error.message}`;
    } else {
      errorMessage = `Le serveur a renvoyé le code ${error.status}: ${error.message}`;
    }
    console.error(errorMessage);
    return throwError(() => new Error(errorMessage));
  }
}

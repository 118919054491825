import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { MergedProduct } from 'src/app/core/models/product.model';

import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { ProductsService } from 'src/app/core/services/products.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { CheckoutService } from 'src/app/shared/services/checkout.service';
import { TrackingService } from 'src/app/shared/services/tracking.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/core/services/user.service';
import { Subscription } from 'rxjs';

//"Application <span class='text-gradient'>Shop extractor</span>",
//"Application <span class='text-gradient'>Image optimizer</span>"
@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
})
export class ProductsComponent implements OnDestroy {
  @Input() home!: boolean;
  @ViewChild('cardsContainer', { static: true }) cardsContainer!: ElementRef;
  private isMobile = false;
  private boundApplyOverlayMask!: (e: PointerEvent) => void;
  private subscriptions: Subscription[] = [];

  loadingProducts: boolean = true;
  skeletonCount = Array(3);
  skeletonListCount = Array(6);
  loading: boolean = false;
  trackingSent: boolean = false;

  selectedPlanIndex: number = 2;
  selectedPackIndex: number = 2;
  visible: boolean = false;
  isLoggedIn: boolean = false;
  isRegister: boolean = false;
  products!: MergedProduct[];
  isCustomer: boolean = false;
  ctaWording!: string;

  schema_data: any = {
    '@context': 'http://schema.org',
    '@type': 'WebApplication',
    '@id': 'phantom-theme.fr',
    name: 'Phantom thème',
    operatingSystem: 'Shopify',
    applicationCategory: 'BusinessApplication',
    browserRequirements: 'Requires Javascript and HTML5 support',
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: '4.7',
      ratingCount: '47',
    },
    screenshot: '',
    inLanguage: ['fr', 'en', 'es', 'sv', 'it', 'de', 'nl', 'pl'],
    description: this.translate.instant('meta_datas.home.meta_description'),
    offers: {},
    creator: {
      '@type': 'Organization',
      '@id': '#organization',
      url: 'https://phantom-theme.fr/',
      name: 'Phantom thème',
      logo: {
        '@type': 'ImageObject',
        url: 'https://cdn.shopify.com/s/files/1/0532/4226/5770/files/logo-phantom-header.png?v=1657057852',
        width: '400px',
        height: '123px',
      },
    },
  };

  constructor(
    private router: Router,
    private authService: AuthService,
    private productsService: ProductsService,
    private checkoutService: CheckoutService,
    private toastService: ToastService,
    private translate: TranslateService,
    private userService: UserService,
    private trackingService: TrackingService,
  ) {}

  selectPlan(planIndex: number): void {
    this.selectedPlanIndex = planIndex;
  }

  ngOnInit(): void {
    const productsSubscription = this.productsService
      .getProductsData()
      .subscribe((products) => {
        this.products = products;

        if (this.products && this.products.length > 0) {
          const contents = this.translate.instant('products.contents');

          this.products.forEach((product, index) => {
            product.lists = contents[index].lists;
            product.description = contents[index].description;
          });

          if (!this.home) {
            this.schema_data.screenshot = this.products[2].demos[1].image;
            this.schema_data.softwareVersion = this.products[2].version;
            this.schema_data.offers = {
              '@type': 'AggregateOffer',
              offeredBy: {
                '@type': 'Organization',
                name: 'Phantom thème',
              },
              highPrice:
                (this.products[2].prices[2].unitAmount / 100) *
                this.products[2].prices[2].intervalCount,
              lowPrice:
                (this.products[0].prices[0].unitAmount / 100) *
                this.products[0].prices[0].intervalCount,
              offerCount: this.products
                .map((product) => product.prices.length)
                .reduce((a, b) => a + b, 0),
              priceCurrency: 'EUR',
              priceSpecification: [],
            };

            this.products.forEach((product) => {
              product.prices.forEach((price) => {
                this.schema_data.offers.priceSpecification.push({
                  '@type': 'UnitPriceSpecification',
                  price:
                    (price.unitAmount / 100) *
                    (price.interval === 'year' ? 12 : price.intervalCount),
                  priceCurrency: 'EUR',
                  name: `${product.name} (${price.nickname})`,
                  referenceQuantity: {
                    '@type': 'QuantitativeValue',
                    unitCode: price.interval === 'year' ? 'ANN' : 'MON',
                    value: price.intervalCount,
                  },
                });
              });
            });

            const script = document.createElement('script');
            script.type = 'application/ld+json';
            script.id = 'productsSchema';
            script.text = JSON.stringify(this.schema_data);
            document.head.appendChild(script);
          }

          this.loadingProducts = false;
        }
      });
    this.subscriptions.push(productsSubscription);

    const isLogged = this.authService.isLoggedIn$.subscribe(
      (isLoggedIn: boolean) => {
        this.isLoggedIn = isLoggedIn;

        if (!isLoggedIn) {
          this.isCustomer = false;
          this.ctaWording = this.translate.instant('products.try');
          return;
        }

        const userData = this.userService.getUserData().subscribe((data) => {
          if (data) {
            if (data.subscriptions.length > 0) {
              this.isCustomer = true;
              this.ctaWording = this.translate.instant('products.buy');
            } else {
              this.isCustomer = false;
              this.ctaWording = this.translate.instant('products.try');
            }
          }
        });
        this.subscriptions.push(userData);
      },
    );
    this.subscriptions.push(isLogged);

    this.isMobile = window.innerWidth < 1200;
  }

  ngAfterViewInit(): void {
    this.isMobile = window.innerWidth < 1200;
    this.boundApplyOverlayMask = this.applyOverlayMask.bind(this);

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !this.trackingSent) {
            this.trackingService
              .sendAppData({
                event_name: 'ViewContent',
              })
              .subscribe();

            this.trackingSent = true;
          }

          if (entry.isIntersecting && !this.isMobile) {
            document.body.addEventListener(
              'pointermove',
              this.boundApplyOverlayMask,
            );
          } else {
            document.body.removeEventListener(
              'pointermove',
              this.boundApplyOverlayMask,
            );
          }
        });
      },
      { threshold: 0.1 },
    );

    observer.observe(this.cardsContainer.nativeElement);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    document.body.removeEventListener(
      'pointermove',
      this.boundApplyOverlayMask,
    );
  }

  applyOverlayMask(e: PointerEvent) {
    if (!this.cardsContainer) return;
    const cardsContainer = this.cardsContainer.nativeElement as HTMLElement;
    const overlay = this.cardsContainer.nativeElement.querySelector(
      '.overlay',
    ) as HTMLElement;

    if (!cardsContainer || !overlay) return;

    const x = e.pageX - cardsContainer.offsetLeft;
    const y = e.pageY - cardsContainer.offsetTop;
    overlay.style.setProperty('--opacity', '1');
    overlay.style.setProperty('--x', `${x}px`);
    overlay.style.setProperty('--y', `${y}px`);
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.isMobile = window.innerWidth < 1200;
  }

  showDialog(index: number) {
    this.selectedPackIndex = index;
    this.visible = true;
  }

  hideDialog() {
    this.visible = false;
  }

  handleDialogClosed() {
    this.visible = false;
  }

  redirectPricing() {
    window.scrollTo(0, 0);
    this.router.navigate(['/pricing']);
  }

  onNddSubmitted(ndd: string) {
    this.loading = true;

    localStorage.setItem('ndd', ndd);
    localStorage.setItem(
      'priceId',
      this.products[this.selectedPackIndex].prices[this.selectedPlanIndex].id,
    );

    this.checkoutService
      .getCheckoutUrl(
        ndd,
        this.products[this.selectedPackIndex].prices[this.selectedPlanIndex].id,
      )
      .subscribe(
        (data) => {
          if (data && data.url) {
            window.location.href = data.url;
          } else {
            this.loading = false;
          }
        },
        (error: any) => {
          this.loading = false;
          if (error.error === 406 || error.error === 409) {
            this.toastService.showToast({
              severity: 'error',
              summary: this.translate.instant('toast.error'),
              detail: this.translate.instant(
                'products.error_duplicate_entry.detail',
              ),
            });
            return;
          }

          this.toastService.showToast({
            severity: 'error',
            summary: this.translate.instant('toast.error'),
            detail: this.translate.instant(
              'products.error_create_checkout.detail',
            ),
          });
        },
      );
  }
}

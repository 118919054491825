import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TrackingService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  sendAppData(appData: any) {
    appData.timestamp = new Date().toISOString();

    return this.http
      .post<any>(this.apiUrl + '/tracking', appData, {
        withCredentials: true,
      })
      .pipe(catchError((error) => this.handleError(error)));
  }

  private handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}

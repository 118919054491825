import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, catchError, tap, throwError } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class SubscriptionsService {
  private subscriptions = new BehaviorSubject<any>(undefined);
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient, private authService: AuthService) {
    if (this.authService.getLoggedInValue()) {
      this.fetchSubscriptionsData();
    } else {
      this.subscriptions.next(null);
    }
  }

  fetchSubscriptionsData() {
    this.http
      .get<any>(this.apiUrl + '/api/licences', {
        withCredentials: true,
      })
      .pipe(
        catchError(this.handleError),
        tap((SubscriptionsData) => {
          this.subscriptions.next(SubscriptionsData);
        })
      )
      .subscribe();
  }

  getSubscriptionsData(): Observable<any> {
    return this.subscriptions.asObservable();
  }

  setSubscriptionNdd(oldNdd: string, ndd: string) {
    return this.http
      .post<any>(
        this.apiUrl + '/api/licences',
        { oldNdd, ndd },
        { withCredentials: true }
      )
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    return throwError(
      () => new Error('Erreur lors de la récupération des données utilisateur')
    );
  }
}

import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { ApiService } from './api-service.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptorService implements HttpInterceptor {
  constructor(
    private router: Router,
    private authService: AuthService,
    private apiService: ApiService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const apiUrl = environment.apiUrl;
    const accessToken = this.authService.getAuthToken();

    if (accessToken === 'undefined') {
      this.authService.removeAuthToken();
      this.router.navigate(['/login'], {
        queryParams: { returnUrl: this.authService.encrypt(req.urlWithParams) },
      });
      return EMPTY;
    }


    if (!accessToken && req.url.startsWith('/dashboard/')) {
      this.router.navigate(['/login'], {
        queryParams: { returnUrl: this.authService.encrypt(req.urlWithParams) },
      });
      return EMPTY;
    }

    let headers = new HttpHeaders();

    const signature = this.apiService.generateSignature(req.body);
    headers = headers.append('X-Signature', signature);
    headers = req.headers.keys().reduce((headers, name) => {
      const values = req.headers.getAll(name);
      if (values) {
        return values.reduce(
          (headers, value) => headers.append(name, value),
          headers
        );
      }
      return headers;
    }, headers);

    if (accessToken) {
      if (!req.url.includes(apiUrl as string)) {
        return next.handle(req);
      } else {
        const cloned = req.clone({
          params: req.params.append('access_token', accessToken as string),
          headers: headers,
        });

        return next.handle(cloned);
      }
    }

    const cloned = req.clone({
      headers: headers,
    });

    return next.handle(cloned);
  }
}

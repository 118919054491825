import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { MenubarModule } from 'primeng/menubar';
import { ButtonModule } from 'primeng/button';
import { SidebarModule } from 'primeng/sidebar';
import { ImageComponent } from './components/image/image.component';
import { PanelMenuModule } from 'primeng/panelmenu';
import { SafeUrlPipe } from '../pipes/safe-url.pipe';
import { FooterComponent } from './components/footer/footer.component';
import { LoggedDrawerComponent } from './components/logged-drawer/logged-drawer.component';
import { NotLoggedDrawerComponent } from './components/not-logged-drawer/not-logged-drawer.component';
import { SharedModule } from '../shared/shared.module';
import { TrialComponent } from './components/trial/trial.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    HeaderComponent,
    ImageComponent,
    SafeUrlPipe,
    FooterComponent,
    LoggedDrawerComponent,
    NotLoggedDrawerComponent,
    TrialComponent,
  ],
  imports: [
    CommonModule,
    MenubarModule,
    ButtonModule,
    SidebarModule,
    PanelMenuModule,
    SharedModule,
    TranslateModule,
  ],
  exports: [HeaderComponent, ImageComponent, SafeUrlPipe, FooterComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CoreModule {}

export const environment = {
  production: true,
  apiUrl: 'https://api.phantom-theme.fr',
  secretKey: 'xBGqmG72PXcxi9u7wcwAwM',
  youtubeApiKey: 'AIzaSyAtx4mzmJzSIEfAd45ASDD9_TfrPGHDdC0',
  youtubeChannelId: 'UCZIozf0LRFGlGE3aGTKyXYA',
  discordToken:
    'MTE4NTMzNzk1OTc1OTc0NTE0NA.GQRo_-._J3F4GR3INAR4AGbJY7BEhK1Q1g58TtMBFdOjs',
  reCaptchaKey: '6Ld9cHQpAAAAAIE1M69-ymOKZl30y-vVIPCPfy09',
  intercomAppSecretKey: 'xbS4ihQktwkjNpPcIqIeiTJKhooFy1lNpWz-Au-H',
  hashKey: '1a6d8fcb97d7d14289eb19803773efa21fc0893e9c71e33a8f937a407b7e1221',
};

import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { DrawerService } from '../../services/drawer.service';
import { UserService } from '../../services/user.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-logged-drawer',
  templateUrl: './logged-drawer.component.html',
  styleUrls: ['./logged-drawer.component.scss'],
})
export class LoggedDrawerComponent implements OnInit, OnDestroy {
  licenses_count: number = 0;
  private userSubscription!: Subscription;
  firstName!: string;
  lastName!: string;
  email!: string;
  subscriptionsLength: number = 0;

  constructor(
    private authService: AuthService,
    private drawerService: DrawerService,
    private userService: UserService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.userSubscription = this.userService
      .getUserData()
      .subscribe((userData) => {
        if (userData) {
          this.firstName = userData.firstname;
          this.lastName = userData.lastname;
          this.email = userData.email;

          this.licenses_count = userData.subscriptions.length;

          this.subscriptionsLength = userData.subscriptions.filter(
            (subscription: any) => !subscription.oneTime
          ).length;
        }
      });
  }

  logout() {
    this.authService.clearAuthToken();
    this.userService.clearUserData();
    this.router.navigate(['/login']);
  }

  toggleSidebar() {
    this.drawerService.toggleSidebar();
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }
}

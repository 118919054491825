import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Message } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';

export interface ExtendedMessage extends Message {
  showButton1?: string;
  showButton2?: string;
  callbackFunction1?: () => void;
  callbackFunction2?: () => void;
  loading1?: false;
  loading2?: false;
}

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private loading1Subject = new BehaviorSubject<boolean>(false);
  loading1$ = this.loading1Subject.asObservable();
  private loading2Subject = new BehaviorSubject<boolean>(false);
  loading2$ = this.loading2Subject.asObservable();

  constructor(private messageService: MessageService) {}

  showToast(options: ExtendedMessage) {
    this.messageService.add({
      severity: options.severity,
      summary: options.summary,
      detail: options.detail,
      life: options.life,
      showButton1: options.showButton1,
      showButton2: options.showButton2,
      callbackFunction1: options.callbackFunction1,
      callbackFunction2: options.callbackFunction2,
      loading1$: this.loading1$,
      loading2$: this.loading2$,
    } as ExtendedMessage);
  }

  setLoading1(value: boolean) {
    this.loading1Subject.next(value);
  }

  setLoading2(value: boolean) {
    this.loading2Subject.next(value);
  }
}
